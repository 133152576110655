<template>
    <div class="pt-6">
        <v-container fluid>
            <v-row>
                <v-col cols="12"> <h1 class="text-h4">Ny kobling</h1></v-col>
                <v-col cols="12">
                    <v-form ref="form" v-model="valid" lazy-validation style="max-width: 800px">
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-select v-model="form.typeId" :items="typer" item-value="id" item-text="navn" :rules="rules.typeId" label="Type"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    label="Bruker"
                                    v-model="form.brukerId"
                                    :items="alleBrukere"
                                    item-text="fulltNavn"
                                    item-value="id"
                                    :rules="rules.brukerId"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    label="Frivillig"
                                    v-model="form.frivilligId"
                                    :items="alleFrivillige"
                                    item-text="fulltNavn"
                                    item-value="id"
                                    :rules="rules.frivilligId"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="form.beskrivelse" label="Beskrivelse" rows="2"></v-textarea>
                            </v-col>
                        </v-row>
                        <br />
                        <v-btn color="primary" @click="create">
                            {{ $t('btn.create') }}
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { /*mapGetters,*/ mapActions } from 'vuex';
//import moment from 'moment';

export default {
    name: 'GriefNewGroup',

    data() {
        return {
            valid: true,

            form: {
                typeId: '',
                brukerId: '',
                frivilligId: '',
                beskrivelse: '',
            },

            typer: [],
            alleBrukere: [],
            alleFrivillige: [],

            rules: {
                typeId: [(v) => !!v || 'Type må oppgis'],
                brukerId: [(v) => !!v || 'Bruker må oppgis'],
                frivilligId: [(v) => !!v || 'Frivillig må oppgis'],
            },
        };
    },

    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/hjemmetjeneste/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        response = await this.request({
            method: 'get',
            url: '/person/bruker/hjemme',
        });

        this.alleBrukere = [];
        if (response && Array.isArray(response)) {
            for (const person of response) {
                if (person.status == 'AKTIV') {
                    this.alleBrukere.push(await this.formatPerson(person));
                }
            }
        }

        response = await this.request({
            method: 'get',
            url: '/person/frivillig/hjemme',
        });

        this.alleFrivillige = [];
        if (response && Array.isArray(response)) {
            for (const person of response) {
                if (person.status == 'AKTIV') {
                    this.alleFrivillige.push(await this.formatPerson(person));
                }
            }
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        create: async function () {
            if (this.$refs.form.validate()) {
                const response = await this.request({
                    method: 'post',
                    url: '/hjemmetjeneste',
                    data: this.form,
                });

                if (response && typeof response.id != 'undefined') {
                    this.snackbar('Hjemmetjeneste opprettet');
                    this.$emit('close');
                    //this.$router.push({ path: '/hjemme/koblinger' });
                }
            }
        },
    },
};
</script>
<style lang="scss"></style>
